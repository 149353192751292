<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" style="min-height: 80vh">
        <base-material-card
          icon="mdi-bus-multiple"
          title="Lineas"
          class="px-5 py-3"
          v-if="!addLine"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">ID</th>
                <th class="primary--text">Name</th>
                <th class="primary--text">Salida</th>
                <th class="primary--text">Llegada</th>
                <th class="text-right primary--text">Estado</th>
                <th class="text-right primary--text">Editar</th>
                <th class="text-right primary--text">Eliminar</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(el, i) in lines" :key="i">
                <td>{{ el.line_id }}</td>
                <td>{{ el.line_name }}</td>
                <td>{{ el.busstops[0].busstop }}</td>
                <td>{{ el.busstops[el.busstops.length - 1].busstop }}</td>
                <td class="text-right">
                  {{ el.active === 1 ? "Activo" : "Inactivo" }}
                </td>
                <td class="text-right">
                  <v-btn
                    color="info"
                    @click="$router.push(`/pages/lines/edit/${el.line_id}`)"
                    >Editar</v-btn
                  >
                </td>
                <td class="text-right">
                  <v-btn
                    color="red"
                    @click="deleteLine(el.line_code, el.route_code)"
                    >Eliminar</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-btn class="mt-6" color="success" @click="addLine = !addLine"
            >Añadir linea</v-btn
          >
        </base-material-card>

        <base-card v-if="addLine">
          <template>
            <div>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <template v-for="n in steps">
                    <v-stepper-step
                      :key="`${n}-step`"
                      :complete="e1 > n"
                      :step="n"
                      editable
                    >
                      Paso {{ n }}
                    </v-stepper-step>
                    <v-divider v-if="n !== steps" :key="n"></v-divider>
                  </template>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content :step="1">
                    <v-card class="mb-12 pb-4" elevation="0">
                      <v-form ref="form" class="px-6" v-model="validStepOne">
                        <v-row>
                          <v-col cols="3">
                            <v-text-field
                              label="Codigo de contrato"
                              v-model="infoLine.contractCode"
                              placeholder="Ej. XG6714"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              label="Codigo de Linea"
                              v-model="infoLine.lineCode"
                              placeholder="Ej. XG67140023"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Nombre de linea"
                              v-model="infoLine.lineName"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              label="Codigo de ruta"
                              v-model="infoLine.routeCode"
                              placeholder="Ej. XG6714"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Nombre de ruta"
                              v-model="infoLine.routeName"
                              placeholder="Ej. XG67140023"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-text-field
                              label="Duración del trayecto"
                              type="number"
                              v-model="infoLine.duration"
                              suffix="Minutos"
                              :rules="[rules.required]"
                              maxlength="6"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              label="Precio"
                              type="number"
                              v-model="infoLine.price"
                              suffix="€"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row justify="center">
                          <v-col cols="2">
                            <v-switch
                              v-model="infoLine.active"
                              label="Linea activa"
                              color="success"
                              hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="2">
                            <v-switch
                              v-model="infoLine.allYear"
                              label="Frecuencia: Todo el año"
                              color="success"
                              hide-details
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-dialog
                              v-if="!infoLine.allYear"
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="infoLine.date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="infoLine.date"
                                  label="Inicio de temporada"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="infoLine.date">
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(infoLine.date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-dialog
                              v-if="!infoLine.allYear"
                              ref="dialog2"
                              v-model="modal1"
                              :return-value.sync="infoLine.date1"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="infoLine.date1"
                                  label="Fin de temporada"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="infoLine.date1">
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal1 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog2.save(infoLine.date1)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card>
                    <v-btn
                      color="primary"
                      :disabled="submitDisabledOne"
                      @click="nextStep(1, 'infoLine', infoLine)"
                    >
                      Continuar
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content :step="2">
                    <v-card class="mb-12 pa-3" elevation="0">
                      <h2>
                        Por favor introduzca las paradas del recorrido en orden
                      </h2>
                      <v-form ref="form2" v-model="validStepTwo">
                        <v-card v-for="(el, i) in countBusstop" class="pa-3">
                          <h3>Número de parada {{ i + 1 }}</h3>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="ID Parada"
                                placeholder="Ej. 12345"
                                v-model="el.idBusstop"
                                :rules="[rules.required]"
                                maxlength="11"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <!-- <v-text-field
                                label="Municipio"
                                placeholder="Ej. Vigo"
                                v-model="el.municipioBusstop"
                                :rules="[rules.required]"
                              ></v-text-field> -->
                              <v-autocomplete
                                :items="municipiosDb"
                                append-inner-icon="mdi-microphone"
                                v-model="el.municipioBusstop"
                                auto-select-first
                                class="flex-full-width"
                                density="comfortable"
                                item-props
                                menu-icon=""
                                label="Municipio"
                                placeholder="Municipio"
                                prepend-inner-icon="mdi-magnify"
                                rounded
                                theme="light"
                                variant="solo"
                              ></v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Nombre Parada"
                                placeholder="Ej. Vigo E.I"
                                v-model="el.nameBusstop"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Codigo de parada"
                                placeholder="Ej. 36039-42"
                                v-model="el.codeBusstop"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Latitud"
                                type="number"
                                placeholder="Ej. 42.2350319"
                                v-model="el.latBusstop"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Longitud"
                                type="number"
                                placeholder=" -8.7128182"
                                v-model="el.lonBusstop"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Tiempo a la siguiente parada, en minutos"
                                type="number"
                                placeholder="10"
                                v-model="el.timeNextBusstop"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex justify-end">
                              <v-btn
                                class="d-flex align-self-end"
                                color="info"
                                small
                                @click="addBusstop(i)"
                                >Añadir parada</v-btn
                              >
                              <v-btn
                                v-if="countBusstop.length > 1"
                                class="d-flex align-self-end"
                                color="red"
                                small
                                @click="deleteBusstop(i)"
                                >Eliminar parada</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>
                    </v-card>

                    <v-btn
                      color="primary"
                      :disabled="!validStepTwo"
                      @click="nextStep(2, 'countBusstop', countBusstop)"
                    >
                      Continue
                    </v-btn>

                    <v-btn text @click="beforeStep(1)"> Atras </v-btn>
                  </v-stepper-content>

                  <v-stepper-content :step="3">
                    <v-card class="mb-12" elevation="0">
                      <v-form ref="form3" v-model="validStepThree">
                        <v-card v-for="(el, i) in countExpedition">
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Codigo ida"
                                v-model="el.expedition_code_departure"
                                placeholder="XG62104701006"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Hora salida ida"
                                v-model="el.time_departure"
                                type="time"
                                suffix="24h."
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Codigo vuelta"
                                v-model="el.expedition_code_arrival"
                                placeholder="XG62104701006"
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                label="Hora salida vuelta"
                                v-model="el.time_arrival"
                                type="time"
                                suffix="24h."
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="px-2">
                            <v-col cols="10">
                              <v-chip
                                v-for="(day, k) in el.week_frequency"
                                :key="k"
                                :color="day.status ? 'primary' : ''"
                                class="ma-2"
                                @click="day.status = !day.status"
                              >
                                {{ day.name }}
                              </v-chip>
                            </v-col>
                            <v-col class="d-flex justify-end">
                              <v-btn
                                class="d-flex align-self-end"
                                color="red"
                                small
                                @click="deleteExpedition(i)"
                                >Eliminar Expedición</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card>
                        <v-row class="d-flex justify-end mt-10"
                          ><v-btn color="info" @click="addExpedition"
                            >Añadir expedicion</v-btn
                          ></v-row
                        >
                      </v-form>
                    </v-card>
                    <div>
                      <v-dialog v-model="dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :disabled="!validStepThree"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              saveCache('countExpedition', countExpedition)
                            "
                          >
                            Guardar Linea
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            ¿Confirma que todos los datos introducidos son
                            correctos?
                          </v-card-title>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="saveLine()">
                              Confirmar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                    <v-btn text @click="beforeStep(2)"> Atras </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </template>
        </base-card>
      </v-col>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../db/municipios.json";
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      municipiosDb: [],
      snackbar: false,
      text: "Linea creada con exito",
      timeout: 2000,
      dialog: false,
      rules: {
        required: (value) => !!value || "Requerido.",
      },
      addLine: false,
      lines: null,
      e1: 1,
      steps: 3,
      menu: false,
      modal: false,
      modal1: false,
      modal2: false,
      infoLine: {
        contractCode: "",
        lineCode: "",
        lineName: "",
        routeName: "",
        routeCode: "",
        duration: "",
        price: "",
        active: false,
        allYear: true,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      modalDepartureTime: false,
      modalArrivalTime: false,
      countBusstop: [
        {
          idBusstop: "",
          municipioBusstop: "",
          nameBusstop: "",
          codeBusstop: "",
          lonBusstop: "",
          latBusstop: "",
          timeNextBusstop: "",
        },
      ],

      countExpedition: [
        {
          expedition_code_departure: "",
          time_departure: "",
          expedition_code_arrival: "",
          time_arrival: "",
          week_frequency: [
            { name: "Lunes", value: "L", status: false },
            { name: "Martes", value: "M", status: false },
            { name: "Miercoles", value: "X", status: false },
            { name: "Jueves", value: "J", status: false },
            { name: "Viernes", value: "V", status: false },
            { name: "Sabado", value: "S", status: false },
            { name: "Domingo", value: "D", status: false },
            { name: "Festivos", value: "F", status: false },
          ],
        },
      ],
      validStepOne: false,
      validStepTwo: false,
      validStepThree: false,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  async mounted() {
    const infoLine = localStorage.getItem("infoLine");
    const countBusstop = localStorage.getItem("countBusstop");
    const countExpedition = localStorage.getItem("countExpedition");
    let op_id = localStorage.getItem("operator_id");
    console.log(db);
    for (let i = 0; i < db.length; i++) {
      this.municipiosDb.push(db[i].nm);
    }
    console.log(this.municipiosDb);
    axios
      .get(
        `https://main-api.apptobus.com/api/get_bus_lines_by_operator_id/2`
        //`https://main-api.apptobus.com/api/get_bus_lines_by_operator_id/${op_id}`
      )
      .then((response) => {
        if (response.status === 200) {
          this.lines = response.data.results;
          console.log(this.lines);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    console.log(infoLine, countBusstop, countExpedition);
    if (infoLine) {
      this.infoLine = JSON.parse(infoLine);
    }
    if (countBusstop) {
      this.countBusstop = JSON.parse(countBusstop);
    }
    if (countExpedition) {
      this.countExpedition = JSON.parse(countExpedition);
    }
  },
  computed: {
    submitDisabledOne() {
      return !this.validStepOne; // devuelve `true` si el formulario no es válido
    },
  },
  methods: {
    nextStep(n, nameObject, data) {
      // Guardamos en local storage el objeto para seguridad de la experiencia de usuario

      localStorage.setItem(nameObject, JSON.stringify(data));

      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    beforeStep(n) {
      // Guardamos en local storage el objeto para seguridad de la experiencia de usuario
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    addExpedition() {
      this.countExpedition.push({
        expedition_code_departure: "",
        time_departure: "",
        expedition_code_arrival: "",
        time_arrival: "",
        week_frequency: [
          { name: "Lunes", value: "L", status: false },
          { name: "Martes", value: "M", status: false },
          { name: "Miercoles", value: "X", status: false },
          { name: "Jueves", value: "J", status: false },
          { name: "Viernes", value: "V", status: false },
          { name: "Sabado", value: "S", status: false },
          { name: "Domingo", value: "D", status: false },
          { name: "Festivos", value: "F", status: false },
        ],
      });
      this.saveCache("countExpedition", this.countExpedition);
    },
    addBusstop(i) {
      this.countBusstop.splice(i + 1, 0, {
        idBusstop: "",
        municipioBusstop: "",
        nameBusstop: "",
        codeBusstop: "",
        lonBusstop: "",
        latBusstop: "",
        timeNextBusstop: "",
      });
      this.saveCache("countBusstop", this.countBusstop);
    },
    deleteBusstop(i) {
      this.countBusstop.splice(i, 1);
      this.saveCache("countBusstop", this.countBusstop);
    },
    deleteExpedition(i) {
      this.countExpedition.splice(i, 1);
      this.saveCache("countExpedition", this.countExpedition);
    },
    saveCache(nameObject, data) {
      localStorage.setItem(nameObject, JSON.stringify(data));
    },
    deleteLine(line_code, route_code) {
      axios
        .get(
          `https://main-api.apptobus.com/api/delete_bus_line/${line_code}/${route_code}`
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveLine() {
      this.dialog = false;
      const busStopsResult = [];
      let acumulator = 0;
      const expeditionCodeDeparture = this.countExpedition.map(
        (val) => val.expedition_code_departure
      );
      const expeditionCodeArrival = this.countExpedition.map(
        (val) => val.expedition_code_arrival
      );
      const weekFrequencyRaw = this.countExpedition.map((val) =>
        val.week_frequency.filter((el) => el.status)
      );
      const weekFrequency = weekFrequencyRaw.map((el) =>
        el.map((el) => el.value).join("")
      );
      const anualFrequency = this.infoLine.allYear
        ? "todo el año"
        : `${this.infoLine.date}, ${this.infoLine.date1}`;
      let timeDeparture = [];
      let timeArrival = [];
      let acumulatorFinal = this.countBusstop.reduce((acumulador, arr) => {
        return acumulador + Number(arr.timeNextBusstop);
      }, 0);
      for (let i = 0; i < this.countBusstop.length; i++) {
        const countBusstop = {
          timeNextBusstop: "",
        };
        if (i === 0) {
          timeDeparture = this.countExpedition.map((el) => el.time_departure);
          timeArrival = this.countExpedition.map((el) =>
            sumarMinutosAHora(el.time_arrival, acumulatorFinal)
          );
        } else {
          acumulator = Number(this.countBusstop[i - 1].timeNextBusstop);
          timeDeparture = timeDeparture.map((el) =>
            sumarMinutosAHora(el, acumulator)
          );
          timeArrival = timeArrival.map((el) =>
            restarMinutosAHora(
              el,
              Number(this.countBusstop[i - 1].timeNextBusstop)
            )
          );
        }

        const busStopDefault = {
          id: null,
          municipality: this.countBusstop[i].municipioBusstop,
          busstop: this.countBusstop[i].nameBusstop,
          time_departure: timeDeparture,
          time_arrival: timeArrival,
          on_demand: 1,
          school_integration: 0,
          ordinal: i + 1,
          bus_stop_id: this.countBusstop[i].idBusstop,
          bus_stop_code: this.countBusstop[i].codeBusstop,
          longitude: this.countBusstop[i].lonBusstop,
          latitude: this.countBusstop[i].latBusstop,
        };
        busStopsResult.push(busStopDefault);
      }

      let resultLine = {
        line_id: null,
        active: this.infoLine.active,
        contract_code: this.infoLine.contractCode,
        line_name: this.infoLine.lineName,
        line_code: this.infoLine.lineCode,
        route_name: this.infoLine.routeName,
        route_code: this.infoLine.routeCode,
        expedition_name: this.infoLine.routeName,
        expedition_code_departure: expeditionCodeDeparture,
        expedition_code_arrival: expeditionCodeArrival,
        week_frequency: weekFrequency,
        anual_frequency: anualFrequency,
        busstops: busStopsResult,
        operator: null,
        operator_id: Number(localStorage.getItem("operator_id")),
        warnings: [
          {
            warning_id: null,
            start_date: "2023-03-28 09:00:00",
            message_en:
              "Intermittent issues have been detected with the service searcher. We are working to fix it as soon as possible. We apologize for any inconvenience caused. ",
            message_es:
              "Se detectan problemas intermitentes con el buscador de servicios. Estamos trabajando para solucionarlo lo antes posible. Disculpen las molestias ocasionadas. ",
            important: false,
          },
        ],
        special_rates: [0.5, 2.7],
        price: this.infoLine.price,
        duration: this.infoLine.duration,
        school_integration: 0,
        school_seats: 0,
        emails: null,
        on_demand: 1,
        ondemand_seats: 0,
        reservable_seats: null,
      };
      function sumarMinutosAHora(hora, minutos) {
        const formatoHora = "HH:mm"; // formato de la hora

        // convertir la cadena de caracteres a un objeto moment
        const momentHora = moment(hora, formatoHora);

        // sumar los minutos
        const horaConMinutosSumados = momentHora.add(minutos, "minutes");

        // retornar la hora con los minutos sumados en formato de cadena de caracteres
        return horaConMinutosSumados.format(formatoHora);
      }

      function restarMinutosAHora(hora, minutos) {
        const formatoHora = "HH:mm"; // formato de la hora

        // convertir la cadena de caracteres a un objeto moment
        const momentHora = moment(hora, formatoHora);

        // restar los minutos
        const horaConMinutosRestados = momentHora.subtract(minutos, "minutes");

        // retornar la hora con los minutos restados en formato de cadena de caracteres
        return horaConMinutosRestados.format(formatoHora);
      }

      console.log(JSON.stringify(resultLine));

      axios
        .post("https://main-api.apptobus.com/api/set_line_data", resultLine)
        .then((response) => {
          if (response.status === 200) {
            localStorage.removeItem('countExpedition')
            localStorage.removeItem('countBusstop')
            localStorage.removeItem('infoLine')
            this.snackbar = true;
            this.addLine = false;
            this.$refs.form.reset()
            this.$refs.form2.reset()
            this.$refs.form3.reset()
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
